<template>
  <div class="container" v-loading="loading">

      <div class="row book-posts">
        <div class="col-xs-12 col-sm-12 col-md-12 book-posts-title">
          {{book}}
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12" v-for="item in list" :key="item.id">
          <div class="book-post-title">
            <router-link :to="{ path: '/book/post', query: { book_id: book_id, id: item.id } }" class="book-post-title-a">
                {{ item.title }}
              </router-link>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // name: 'Index',
  props:['user'],
  data() {
    return {
      sliders: null,
      book_id: null,
      book: null,
      list: null,
      loading: true,
    }
  },
  created() {
    this.getNewCourseList()
  },
  methods: {
    async getNewCourseList() {
      const response = await axios.post('/api/web/book?id='+this.$route.query.id)
      this.list = response.data.data.posts

      const book = Object.values(response.data.data.book)
      this.book_id = book[0]
      this.book = book[1]
      // console.log(this.book)
      // console.log(Object.prototype.toString.call(book))
      this.loading = false
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
